import React from 'react'

export default ({values, handleChange, selectedValues}) => {
    return (
        <ul className="checkbox-list checkboxes">
            {values.map((term, index) => {
                return (
                    <li key={index}>
                        <div className="checkbox" key={index}>
                            <label>
                                <span className="checkbox-container">
                                    <input 
                                        type="checkbox" 
                                        onChange={() => {handleChange(term)}} 
                                        /*checked={selectedValues.indexOf(term) !== -1 ? true : false}*/
                                    />
                                    <span className="checkmark" />
                                </span>
                                {term}
                            </label>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}