import React, { useEffect, useState, useRef } from "react"
import { forIn } from 'lodash'
import { graphql, Link } from "gatsby"
import cloneDeep from 'lodash/cloneDeep'
import SEO from "components/seo"
import CheckboxList from 'components/checkBoxList'
import { debounce } from 'lodash'
import alarmIcon from "../images/alarm-icon.png"
import locationIcon from "../images/location-icon.png"
import axios from 'axios'
import Button from 'components/button'

export default ({
	
}) => {
	const viewIncrementer = 9

	const [availableJobs, setAvailableJobs] = useState([])
	const [isLoading, setIsloading] = useState(true)
	const [searchTerm, setSearchTerm] = useState('')
	const [searchInput, setSearchInput] = useState('')
	const [resultsVisible, setResultsVisible] = useState(true)
	const [resultsToShow, setResultsToShow] = useState(viewIncrementer)

	const [filterState, setFilterState] = useState({})

    const searchTermRef = useRef(searchTerm)
	searchTermRef.current = searchTerm
	const textInput = useRef(null)
	
	const _handleChangeSearchTerm = term => {
		handleResultsChange(() => setSearchTerm(term))
	}
	
	const debouncedHandleChangeSearchTerm = useRef(debounce(_handleChangeSearchTerm, 300))

    const handleChangeSearchTerm = term => {

		debouncedHandleChangeSearchTerm.current(term)
		
		setSearchInput(term)
	}

	useEffect(() => {
		axios({
			method: 'get',
			url: `https://applynow.net.au/api/v2/jobs?recursive=true&current=true`,
			headers: {
				'Content-Type': 'application/json',
				'X-api-authenticate': 'b76c95cd32e88997f275c72cef11bc08dbdf907f'
			}
		}).then(response => {
			setIsloading(false)
			const { jobs } = response.data

			setAvailableJobs(jobs)
		}).catch(_error => {
			setIsloading(false)
			console.log('ERROR IS')
			console.log(_error)
		})
	}, [])

	const taxonomies = [
		{
			slug: 'states',
			name: 'States',
			enum: 'STATE',
		},
		{
			slug: 'types',
			name: 'Types',
			enum: 'TYPE',
		},
	]
	
	const handleResultsChange = callback => {
		setResultsVisible(false)

		setTimeout(() => {
			callback()

			setResultsVisible(true)
		}, 250)
	}

	const handleChange = (taxonomySlug, termSlug) => {
		const _filterState = cloneDeep(filterState)

		if (_filterState[taxonomySlug]) {
			const slugIndex = _filterState[taxonomySlug].indexOf(termSlug)

			if (slugIndex !== -1) {
				_filterState[taxonomySlug].splice(slugIndex, 1)
			} else {
				_filterState[taxonomySlug].push(termSlug)
			}
		} else {
			_filterState[taxonomySlug] = [termSlug]
		}

		handleResultsChange(() => setFilterState(_filterState))
	}

	const generateTaxQueryObject = (terms, key) => {
		return {
			terms: terms,
			taxonomy: key,
			operator: 'IN',
			field: 'SLUG'
		}
	}

	useEffect(() => {
		const taxQuery = []

		forIn(filterState, (filter, filterKey) => {
			if (filter) {
				taxQuery.push(generateTaxQueryObject(filter, filterKey))
			}
		})

	}, filterState)

	const normaliseValues = (state, type) => {
		let _state = state.toUpperCase().trim()

		if(type === 'STATE'){
			switch(_state){
				case 'VICTORIA':
					_state = 'VIC'
					break
				case 'NORTHERN NSW':
					_state = 'NSW'
					break
				case 'MACKAY':
					_state = 'QLD'
					break
			}
		}

		return _state
	}

	const meta = (() => {
		const _meta = {
			states: {
				label: 'State',
				values: []
			},
			types: {
				label: 'Types',
				values: []
			}
		}

		availableJobs.forEach(job => {
			const {
				address_state,
				employment_status
			} = job

			const state = normaliseValues(address_state, 'STATE')

			if (_meta.states.values.indexOf(state) === -1) {
				_meta.states.values.push(state)
			}

			const status = employment_status.toUpperCase().trim()

			if (_meta.types.values.indexOf(status) === -1) {
				_meta.types.values.push(status)
			}
		})

		Object.keys(_meta).forEach(key => {
			const values = _meta[key].values

			values.sort((a,b) => {
				if(a < b) { return -1 }
				if(a > b) { return 1 }
				return 0
			})
		})

		return _meta
	})()

	const _filteredJobs = availableJobs.filter(job => {
		let isVisible = true

		Object.keys(filterState).forEach(key => {
			const selectedValues = filterState[key]

			if(selectedValues.length != 0) {
				let jobKey = ''

				switch(key){
					case 'STATE':
						jobKey = 'address_state'
						break
					case 'TYPE':
						jobKey = 'employment_status'
						break
				}
	
				const jobValue = normaliseValues(job[jobKey], key)
	
				const isValueInSelection = selectedValues.find(value => {
					return value === jobValue
				})
				
				if(!isValueInSelection){
					isVisible = false
				}
			}

		})
		const jobtitle = job.title.toLowerCase()

		if(jobtitle.indexOf(searchTerm.toLowerCase()) === -1) {
			isVisible = false
		}

		return isVisible
	})

	const handleShowMore = _ => {
		setResultsToShow(resultsToShow + viewIncrementer)
	}

	const _meteredResults = _filteredJobs.length > resultsToShow ? _filteredJobs.slice(0, resultsToShow) : _filteredJobs
	const hasMoreResults = _filteredJobs.length > _meteredResults.length

	return (
		<>
			<SEO title='Careers' descriptions='Get ahead of your career with Strikeforce.' />
			<div className="careers-hub">
				<div className="careers-hub-hero">
					<div className="container">
						<h1 
							className="h2" 
							dangerouslySetInnerHTML={{ __html: 'Get ahead of your career with Strikeforce.' }} 
						/>
					</div>
				</div>
				<div className="container">
					<div className="search-form-container">
						<p dangerouslySetInnerHTML={{ __html: 'Find out what is available.' }} />
						<form>
							<input
								className="keyword"
								type="text"
								placeholder="Search Keyword"
								value={searchInput}
								onChange={e => handleChangeSearchTerm(e.target.value)}
								ref={ref => textInput.current = ref}
							/>
						</form>
					</div>
					<div className="search-results-container">
						<div className="sidebar">
							<ul className="filters">
								{taxonomies.map((taxonomy, index) => {
									const values = meta[taxonomy.slug].values

									const numberOfValues = values.length

									return (
										<li key={index}>
											<h4 dangerouslySetInnerHTML={{ __html: taxonomy.name + ' (' + numberOfValues + ')' }} />
											<CheckboxList
												taxonomy={taxonomy.slug}
												values={values}
												handleChange={termSlug => handleChange(taxonomy.enum, termSlug)}
												selectedValues={filterState[taxonomy.slug] || []}
											/>
											<div className="count">
											</div>
										</li>
									)
								})}
							</ul>
						</div>
						<div className={`list-container ${ resultsVisible ? 'visible' : ''}`}>
							<p>
								Showing <strong>{_meteredResults.length}</strong> of <strong>{ _filteredJobs.length }</strong> jobs
              				</p>
							<ul>
								{ !isLoading ?
									_meteredResults.map((job, index) => {
											return (
												<li key={index}>
													<Link
														to={'/single-career?id='+job.id}
													>
														<div className="info">
															<h3 dangerouslySetInnerHTML={{ __html: job.title }} />
															<span>
																Job No: {job.reference}
															</span>
															<p dangerouslySetInnerHTML={{ __html: job.summary }} />
														</div>
														<div className="meta">
															<ul>
																<li>
																	<img src={locationIcon} />
																	<span>
																		{job.location}
																	</span>
																</li>
																<li>
																	<img src={alarmIcon} />
																	<span>
																		{job.employment_status}
																	</span>
																</li>
															</ul>
														</div>
													</Link>
												</li>
											)
									
										})
										: null
									}
									{ hasMoreResults &&
										<div className={'show-more-row'}>
											<Button
												label={'Show more results'}
												onClick={handleShowMore}
												showArrow={'false'}
												type={'button'}
												isLink={false}
											/>
										</div>
									}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}